import "./Page404.css"

const Page404 = () => {
  return (
    <div className="page-404">
      <div><img src="/404.png" alt="" /></div>
    </div>
  )
}

export default Page404
